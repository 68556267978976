
import { defineComponent, reactive, ref } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import ProdutoDetalhes from '@/components/Cadastros/Produtos/ProdutoDetalhes.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import LeitorCodigoCamera from '@/core/components/Dispositivo/LeitorCodigoCamera.vue';
import { IDTOBalancoEstoqueItemContagem } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemContagem';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import storeSistema from '@/store/storeSistema';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';
import { IBalancoEstoqueItem, IBalancoEstoqueItemContagem } from '@/models/Entidades/Estoques/IBalancoEstoque';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IDTOProdutoDefinicaoIdentificacao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoIdentificacao';
import { IUnidade } from '@/models/Entidades/Cadastros/Produtos/IUnidade';
import ServicoUnidade from '@/servicos/Cadastros/Produtos/ServicoUnidade';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';

export default defineComponent({
  name: 'BalancoEstoqueContagem',
  props: {
    codigoBalancoEstoque: {
      type: Number,
      required: true,
    },
    codigoEmpresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    SelecionarProduto,
    SelecionarEstoque,
    ProdutoDetalhes,
    LeitorCodigoCamera,
  },
  emits: ['confirmadaContagemEstoque'],
  setup(props, { emit }) {
    const { apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const refContagem = ref<HTMLElement | null>(null);

    const servicoBalancoEstoque = new ServicoBalancoEstoque();
    const servicoEstoque = new ServicoEstoque();
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      apresentarDetalhesProduto: false,
      permitirSelecionarProduto: false,
      apresentarRastreabilidade: false,
      autoIncrementar: false,
      itemContagem: {} as IDTOBalancoEstoqueItemContagem,
      identificacaoDefinicao: {} as IDTOProdutoDefinicaoIdentificacao,
      apresentarLeitorCodigoCamera: false,
      cameraDisponivel: false,
      codigoParaBusca: '',
      unidadesCadastradas: [] as IUnidade[],
      mensagemSalvandoContagem: '',
      estoqueCadastrados: [] as IEstoque[],
    });

    state.cameraDisponivel = UtilitarioDispositivo.cameraDisponivel();

    function obterEstoque() {
      if (state.estoqueCadastrados.length === 1) {
        state.itemContagem.codigoEstoque = state.estoqueCadastrados[0].codigo;
      }
    }

    (async () => {
      state.unidadesCadastradas = await new ServicoUnidade().obterTodasUnidades([props.codigoEmpresa]);
      state.estoqueCadastrados = await servicoEstoque.obterTodosEstoques(props.codigoEmpresa);
      obterEstoque();
    })();

    async function incluirItemEstoque():Promise<number> {
      const itemEstoque:IEstoqueItem = {} as IEstoqueItem;
      itemEstoque.codigo = 0;
      itemEstoque.codigoEstoque = state.itemContagem.codigoEstoque;
      itemEstoque.codigoEmpresa = props.codigoEmpresa;
      itemEstoque.codigoProdutoDefinicao = state.itemContagem.codigoDefinicao;
      itemEstoque.codigoUnidade = state.itemContagem.codigoUnidade;
      itemEstoque.bloqueado = false;

      let retornoItemEstoque: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retornoItemEstoque = await servicoEstoque.incluirItens(state.itemContagem.codigoEstoque, [itemEstoque]);
      if (retornoItemEstoque.status === EStatusRetornoRequisicao.Sucesso) {
        return retornoItemEstoque.codigoRegistro;
      }
      apresentarMensagemAlerta(retornoItemEstoque.mensagem);

      return 0;
    }

    async function incluirItemBalancoEstoque(codigoItemEstoque:number):Promise<number> {
      const itemBalancoEstoque:IBalancoEstoqueItem = {} as IBalancoEstoqueItem;
      itemBalancoEstoque.codigo = 0;
      itemBalancoEstoque.codigoBalancoEstoque = props.codigoBalancoEstoque;
      itemBalancoEstoque.codigoEstoqueItem = codigoItemEstoque;
      itemBalancoEstoque.quantidadeEstoque = 0;
      itemBalancoEstoque.quantidadeEstoqueBloqueada = 0;
      itemBalancoEstoque.quantidadeAjuste = 0;
      itemBalancoEstoque.quantidadeSaldoFinal = 0;
      itemBalancoEstoque.possuiContagem = true;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retorno = await servicoBalancoEstoque.adicionarItens(props.codigoBalancoEstoque, [itemBalancoEstoque]);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        return retorno.codigoRegistro;
      }
      apresentarMensagemAlerta('Não foi possível incluir o item balanço de estoque!');

      return 0;
    }

    function criaObjetoItemBalancoContagem(codigoItemBalancoContagem?:number):IBalancoEstoqueItemContagem {
      const itemBalancoContagem:IBalancoEstoqueItemContagem = {} as IBalancoEstoqueItemContagem;

      itemBalancoContagem.codigo = 0;
      if (codigoItemBalancoContagem !== undefined) { itemBalancoContagem.codigo = codigoItemBalancoContagem; }
      itemBalancoContagem.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      itemBalancoContagem.quantidadeContada = state.itemContagem.quantidadeContada;
      itemBalancoContagem.codigoBalancoEstoqueItem = state.itemContagem.codigoItemBalanco;
      return itemBalancoContagem;
    }

    function limparContagem() {
      state.itemContagem = {} as IDTOBalancoEstoqueItemContagem;
      state.itemContagem.codigo = 0;
      state.itemContagem.codigoUnidade = 0;
      state.itemContagem.unidade = '';
      state.itemContagem.sigla = '';
      state.itemContagem.quantidadeContada = 0;
      state.itemContagem.casasDecimais = 0;

      state.identificacaoDefinicao = {
        codigoProduto: 0, tipoProduto: 0, codigoDefinicao: 0, codigoUnidade: 0, codigoInterno: '',
      } as IDTOProdutoDefinicaoIdentificacao;
      obterEstoque();
    }

    function contagemConcluidaComSucesso() {
      emit('confirmadaContagemEstoque', state.itemContagem);
      state.mensagemSalvandoContagem = '';
      apresentarMensagemSucesso('Contagem confirmada com sucesso!');
      limparContagem();
    }

    function salvarRastreabilidadeItem() {
      return '';
    }

    async function confirmarContagemEstoque() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      if (!UtilitarioGeral.validaCodigo(state.itemContagem.codigoDefinicao)) {
        apresentarMensagemAlerta('Nenhum produto foi selecionado!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.itemContagem.codigoEstoque)) {
        apresentarMensagemAlerta('Nenhum estoque foi selecionado!');
        return;
      }
      state.mensagemSalvandoContagem = 'Aguarde por favor';

      const itemEstoqueApi = await servicoEstoque.obterItemEstoqueInformacoesProduto(state.itemContagem.codigoEstoque, state.itemContagem.codigoDefinicao, state.itemContagem.codigoUnidade, props.codigoEmpresa);
      let codigoItemEstoque = 0;
      let novoItemEstoque = false;
      if (UtilitarioGeral.objetoValido(itemEstoqueApi)) {
        codigoItemEstoque = itemEstoqueApi.codigo;
      } else {
        state.mensagemSalvandoContagem = 'Criando item no estoque';
        codigoItemEstoque = await incluirItemEstoque();
        novoItemEstoque = true;
      }
      if (!UtilitarioGeral.validaCodigo(codigoItemEstoque)) {
        state.mensagemSalvandoContagem = '';
        return;
      }

      let codigoItemBalancoEstoque = 0;
      if (novoItemEstoque) {
        state.mensagemSalvandoContagem = 'Adicionando item no balanço';
        codigoItemBalancoEstoque = await incluirItemBalancoEstoque(codigoItemEstoque);
      } else {
        const listaItemBalanco = await servicoBalancoEstoque.obterContagens(props.codigoBalancoEstoque, props.codigoEmpresa, [storeSistema.getters.codigoUsuarioAutenticado()], [state.itemContagem.codigoDefinicao]);
        if (UtilitarioGeral.validaLista(listaItemBalanco)) {
          const itemBalanco = listaItemBalanco.find((c) => c.codigoItemEstoque === codigoItemEstoque);
          if (itemBalanco !== undefined) {
            state.itemContagem.codigoItemBalanco = itemBalanco.codigoItemBalanco;
            state.mensagemSalvandoContagem = 'Salvando informações';
            retorno = await servicoBalancoEstoque.lancarContagem(props.codigoBalancoEstoque, props.codigoEmpresa, criaObjetoItemBalancoContagem(itemBalanco.codigo), state.autoIncrementar);
            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
              contagemConcluidaComSucesso();
              return;
            }
            apresentarMensagemAlerta(retorno.mensagem);
            state.mensagemSalvandoContagem = '';
            return;
          }
        }
      }

      const itemBalancoEstoque = await servicoBalancoEstoque.obterItemBalancoPorEstoqueItem(props.codigoBalancoEstoque, codigoItemEstoque);
      if (itemBalancoEstoque.codigo === null || itemBalancoEstoque.codigo === undefined) {
        state.mensagemSalvandoContagem = 'Adicionando item no balanço';
        codigoItemBalancoEstoque = await incluirItemBalancoEstoque(codigoItemEstoque);
      } else {
        codigoItemBalancoEstoque = itemBalancoEstoque.codigo;
      }

      if (!UtilitarioGeral.validaCodigo(codigoItemBalancoEstoque)) {
        state.mensagemSalvandoContagem = '';
        return;
      }

      state.itemContagem.codigoItemBalanco = codigoItemBalancoEstoque;
      state.mensagemSalvandoContagem = 'Salvando informações';
      retorno = await await servicoBalancoEstoque.lancarContagem(props.codigoBalancoEstoque, props.codigoEmpresa, criaObjetoItemBalancoContagem(), state.autoIncrementar);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemAlerta(retorno.mensagem);
        state.mensagemSalvandoContagem = '';
        return;
      }

      contagemConcluidaComSucesso();
    }

    async function obterDadosIdentificacaoDefinicao(codigoDefinicao:number) {
      state.identificacaoDefinicao = {
        codigoProduto: 0, tipoProduto: 0, codigoDefinicao: 0, codigoUnidade: 0, codigoInterno: '',
      } as IDTOProdutoDefinicaoIdentificacao;

      if (UtilitarioGeral.validaCodigo(codigoDefinicao)) {
        const dadosIdentificacaoApi = await servicoProduto.obterDadosIdentificacaoDefinicao(codigoDefinicao);
        if (UtilitarioGeral.objetoValido(dadosIdentificacaoApi)) {
          state.identificacaoDefinicao = dadosIdentificacaoApi;
          const unidadeProduto = state.unidadesCadastradas.find((c) => c.codigo === dadosIdentificacaoApi.codigoUnidade);
          if (unidadeProduto !== undefined) {
            state.itemContagem.codigoProduto = dadosIdentificacaoApi.codigoProduto;
            state.itemContagem.codigoUnidade = unidadeProduto.codigo;
            state.itemContagem.unidade = `${unidadeProduto.sigla} - ${unidadeProduto.descricao}`;
            state.itemContagem.casasDecimais = unidadeProduto.casasDecimais;

            if (state.autoIncrementar) {
              state.itemContagem.quantidadeContada = 1;
              await confirmarContagemEstoque();
            }
          }
        }
      }
    }

    function codigoIdentificadoNaLeitura(codigo:string) {
      state.codigoParaBusca = codigo;
    }

    function montaTituloContagem():string {
      return `${state.itemContagem.codigo > 0 ? 'EDITANDO' : 'LANÇAR'} CONTAGEM DOS PRODUTOS`;
    }

    function editarContagem(itemContagem: IDTOBalancoEstoqueItemContagem) {
      state.itemContagem = itemContagem;
      state.itemContagem.unidade = `${itemContagem.sigla} - ${itemContagem.unidade}`;
    }
    return {
      props,
      state,
      refContagem,
      confirmarContagemEstoque,
      obterDadosIdentificacaoDefinicao,
      codigoIdentificadoNaLeitura,
      montaTituloContagem,
      editarContagem,
      limparContagem,
      obterEstoque,
      salvarRastreabilidadeItem,
    };
  },
});
