
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoRastreabilidade';
import SelecionarTipoRastreabilidade from '@/components/Estoques/Rastreabilidade/SelecionarTipoRastreabilidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import SelecionarTipoUsoArma from '@/components/Estoques/Rastreabilidade/SelecionarTipoUsoArma.vue';
import ServicoRastreabilidade from '@/servicos/Estoques/ServicoRastreabilidade';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ETipoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/ETipoMovimentoEstoque';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOMovimentoRastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTOMovimentoRastreabilidade';
import { IDTORastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTORastreabilidade';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';

export default defineComponent({
  name: 'DetalheRastreabilidadeModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    rastreabilidade: {
      type: Object as () => IDTORastreabilidade,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarTipoRastreabilidade,
    CampoNumerico,
    SelecionarTipoUsoArma,
    PreviewPdf,
    MensagemSemDados,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemErro } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase, carregarPreferenciasGrade } = useGradeBase();
    const servicoRastreabilidade = new ServicoRastreabilidade();
    servicoRastreabilidade.requisicaoSistema();

    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasPadrao = [
      {
        title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
      },
      {
        title: 'Data', dataIndex: 'data', key: 'data', position: 1, visible: true, ordering: true, align: 'left', width: 140, customRenderRow: ECustomRenderRow.MascararData,
      },
      {
        title: 'Nº do Movimento', dataIndex: 'numeroMovimento', key: 'NumeroMovimento', position: 2, visible: true, ordering: true, align: 'left', width: 140,
      },
      {
        title: 'Origem do Movimento', dataIndex: 'recurso', key: 'recurso', position: 3, visible: true, ordering: true, align: 'left', width: 200,
      },
      {
        title: 'Cliente/Fornecedor', dataIndex: 'nome', key: 'Nome', position: 4, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 5, visible: true, ordering: true, align: 'right', customRenderRow: ECustomRenderRow.MascararFracionado, width: 100,
      },
    ];

    gradeBase.colunasMobile = [
      {
        title: 'Movimento', dataIndex: '', key: 'Movimento', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      movimentos: [] as IDTOMovimentoRastreabilidade[],
      arquivosPdf: [] as IArquivoPdf[],
      entradas: 0,
      saidas: 0,
    });

    async function limparTela() {
      state.movimentos = [] as IDTOMovimentoRastreabilidade[];
      state.arquivosPdf = [] as IArquivoPdf[];
      state.entradas = 0;
      state.saidas = 0;
    }

    async function imprimir() {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoRastreabilidade.relatorioMovimentacoes(props.rastreabilidade.codigo, props.empresas[0], ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function obterMovimentacoes() {
      state.movimentos = await servicoRastreabilidade.obterMovimentacoes(props.rastreabilidade.codigo, props.empresas[0]);
      state.movimentos.forEach((m) => {
        if (m.tipo === ETipoMovimentoEstoque.Entrada) {
          state.entradas += m.quantidade;
        } else {
          state.saidas += m.quantidade;
        }
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        carregarPreferenciasGrade(telaBase.preferencias);
        await obterMovimentacoes();
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      ETipoRastreabilidade,
      ECustomRenderRow,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      imprimir,
    };
  },
});
