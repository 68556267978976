
import {
  defineComponent, reactive, onMounted, watch, ref, computed,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useModalBase } from '@/core/composables/ModalBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import BalancoEstoqueContagem from './BalancoEstoqueContagem.vue';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IDTOBalancoEstoqueItemResumo } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemResumo';
import { IBalancoEstoqueConferente } from '@/models/Entidades/Estoques/IBalancoEstoque';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import ServicoImportacaoEstoques from '@/servicos/ImportacoesRegistros/ServicoImportacaoEstoques';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IDTOBalancoEstoqueItemContagem } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemContagem';
import storeSistema from '@/store/storeSistema';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IRastreabilidade } from '@/models/Entidades/Estoques/IRastreabilidade';
import LancamentoRastreabilidadeModal from '../Rastreabilidade/LancamentoRastreabilidadeModal.vue';

export default defineComponent({
  name: 'BalancoEstoqueItens',
  props: {
    codigoBalancoEstoque: {
      type: Number,
      required: true,
    },
    codigoEmpresa: {
      type: Number,
      required: true,
    },
    codigoResponsavel: {
      type: Number,
      required: true,
    },
    conferentes: {
      type: Array as () => IBalancoEstoqueConferente[],
      required: true,
    },
  },
  components: {
    Icone,
    BarraProgresso,
    MensagemSemDados,
    BuscaAvancada,
    SelecionarEstoque,
    BalancoEstoqueContagem,
    RequisicaoModal,
    LancamentoRastreabilidadeModal,
  },
  emits: ['atualizarRevisao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();

    const { modalBase } = useModalBase(props, emit);

    const {
      gradeBase, preenchePropriedadesNosFiltrosEOrdenacao, defineValoresIniciaisGrade,
      preencheOrdenacaoSelecionada, ordenacaoAtiva, ordenarDados, mudarPagina, mudarQuantidadeRegistrosPorPagina,
      preencheCodigosSelecionados, alterarQuantidadeRegistroTotal, obtemLarguraGrade,
    } = useGradeBase();

    const servicoBalancoEstoque = new ServicoBalancoEstoque();
    const servicoProduto = new ServicoProduto();
    const servicoImportacaoEstoques = new ServicoImportacaoEstoques();
    const refBalancoEstoqueContagem = ref<InstanceType<typeof BalancoEstoqueContagem>>();
    let arquivoUpload = new FormData();

    enum EApresentarProdutosContados{
      UltimosLancamentos = 1,
      TodosProdutos = 2,
      BuscaAvancada = 3,
    }
    const state = reactive({
      itens: [] as IDTOBalancoEstoqueItemContagem[],
      modoApresentacaoProdutos: {} as EApresentarProdutosContados,
      codigoConferente: 0,
      estoquesCadastrados: [] as IEstoque[],
      empresaComVariosEstoques: false,
      editarContagem: {} as IDTOBalancoEstoqueItemContagem,
      importacaoArquivoEmAndamento: false,
      resumoImportacaoArquivo: '',
      codigoEstoqueImportacao: 0,
      listaArquivos: [],
      exibirBuscaProduto: false,
      itemEstoqueSelecionado: 0,
      codigoItemSelecionado: 0,
      quantidadeSelecionado: 0,
      rastreabilidades: [] as IRastreabilidade[],
      indexItemSelecionado: -1,
      apresentarRastreabilidade: false,
    });

    state.modoApresentacaoProdutos = EApresentarProdutosContados.UltimosLancamentos;

    function verificaPosicaoColuna():string | undefined {
      if (UtilitarioDispositivo.larguraTelaMobile()) {
        return undefined;
      }
      return 'left';
    }
    function montaGradeProdutos() {
      gradeBase.colunas = [
        {
          title: 'Código Definição', dataIndex: 'codigoDefinicao', key: 'CodigoDefinicao', position: 0, visible: false, ordering: false,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 60, ellipsis: true, position: 1, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'right',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 250, ellipsis: true, position: 2, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'left',
        },
        {
          title: 'UN', dataIndex: 'sigla', key: 'UnidadeProduto', width: 30, ellipsis: true, position: 3, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'center',
        },
        {
          title: 'Estoque', dataIndex: 'estoque', key: 'estoque', width: 70, position: 4, visible: state.empresaComVariosEstoques, ordering: false, align: 'left',
        },
        {
          title: 'Quantidades contadas', dataIndex: 'quantidadeContada', key: 'quantidadeContada', width: 80, position: 5, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'Ações', key: 'acoes', position: 0, visible: true, width: 30, align: 'center', fixed: 'right',
        },
      ];
    }
    function verificaEstoquesEmpresa() {
      state.empresaComVariosEstoques = false;

      if (UtilitarioGeral.validaCodigo(props.codigoEmpresa)) {
        if (UtilitarioGeral.validaLista(state.estoquesCadastrados)) {
          let quantidadeEstoqueDisponiveis = 0;
          const estoquesAtivos = state.estoquesCadastrados.filter((c) => c.ativo === true);
          if (UtilitarioGeral.validaLista(estoquesAtivos)) {
            estoquesAtivos.forEach((estoque) => {
              if (estoque.empresas.some((c) => c.codigoEmpresa === props.codigoEmpresa)) {
                quantidadeEstoqueDisponiveis += 1;
              }
            });
          }

          state.empresaComVariosEstoques = (quantidadeEstoqueDisponiveis > 1);
        }
      }
    }

    (async () => {
      state.estoquesCadastrados = await new ServicoEstoque().obterTodosEstoques(props.codigoEmpresa);
    })();

    state.itens = [];
    gradeBase.buscaAvancada.filtrosAdicionados = [];
    gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
    gradeBase.ordenacaoSelecionada = [];
    gradeBase.colunas = [];

    function obtemCodigoEmpresa():number {
      verificaEstoquesEmpresa();
      montaGradeProdutos();
      return props.codigoEmpresa;
    }
    const computedCodigoEmpresa = computed(() => obtemCodigoEmpresa());

    async function buscarDados() {
      state.itens = [];
      if (props.codigoEmpresa === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      gradeBase.filtrosAplicados = [];
      gradeBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = [props.codigoEmpresa];
      parametrosConsulta.numeroPagina = gradeBase.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = gradeBase.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = gradeBase.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      gradeBase.buscandoDados = true;
      const parametrosConsultaProdutoDefinicao: IParametrosConsultaProdutoDefinicao = {} as IParametrosConsultaProdutoDefinicao;
      parametrosConsultaProdutoDefinicao.apenasAtivos = false;
      parametrosConsultaProdutoDefinicao.presentesBalancoEstoque = props.codigoBalancoEstoque;

      const listaPaginada = await servicoProduto.buscaAvancadaDefinicoes(parametrosConsulta, gradeBase.filtrosAplicados, parametrosConsultaProdutoDefinicao);

      if (listaPaginada !== undefined && listaPaginada !== null) {
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          const codigoDefinicoes = listaPaginada.dados.map((produto: IDTOProdutoDefinicao) => produto.codigoDefinicao);

          const produtosDefinicoes = await servicoBalancoEstoque.obterContagens(props.codigoBalancoEstoque, props.codigoEmpresa, [storeSistema.getters.codigoUsuarioAutenticado()], codigoDefinicoes);
          if (UtilitarioGeral.validaLista(produtosDefinicoes)) {
            state.itens = produtosDefinicoes;
            gradeBase.paginacaoApi = listaPaginada.metaData;
          }
        }
      }

      gradeBase.buscandoDados = false;
    }

    watch(async () => gradeBase.buscarDados, async () => {
      await buscarDados();
    });

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      if (UtilitarioGeral.validaLista(gradeBase.buscaAvancada.filtrosAdicionados)) {
        await buscarDados();
      } else {
        state.itens = [];
      }
    }

    function defineParametrosConsultaNovosProdutos(): IParametrosConsultaProdutoDefinicao {
      const parametrosConsultaProdutoDefinicao: IParametrosConsultaProdutoDefinicao = {} as IParametrosConsultaProdutoDefinicao;
      parametrosConsultaProdutoDefinicao.apenasAtivos = true;
      parametrosConsultaProdutoDefinicao.ausentesBalancoEstoque = props.codigoBalancoEstoque;
      return parametrosConsultaProdutoDefinicao;
    }

    function apresentarSelecaoNovosProdutos() {
      state.exibirBuscaProduto = true;
    }

    async function atualizarContagemDefinicao(consolidacaoEstoqueItem:IDTOBalancoEstoqueItemResumo) {
      console.log(consolidacaoEstoqueItem);
    }

    function aplicarCasasDecimaisQuantidade(valor:number, quantidadeCasas: number):string {
      const precoFormatado = UtilitarioMascara.mascararValor(valor, quantidadeCasas);
      return precoFormatado;
    }

    async function obterContagens() {
      if (state.modoApresentacaoProdutos === EApresentarProdutosContados.UltimosLancamentos) {
        state.itens = await servicoBalancoEstoque.obterContagens(props.codigoBalancoEstoque, props.codigoEmpresa, [storeSistema.getters.codigoUsuarioAutenticado()], [], true);
      } else if (state.modoApresentacaoProdutos === EApresentarProdutosContados.TodosProdutos) {
        state.itens = await servicoBalancoEstoque.obterContagens(props.codigoBalancoEstoque, props.codigoEmpresa, [storeSistema.getters.codigoUsuarioAutenticado()], [], true);
      } else {
        await buscarDados();
      }
    }

    async function confirmadaContagemEstoque() {
      state.editarContagem = {} as IDTOBalancoEstoqueItemContagem;
      await obterContagens();
      emit('atualizarRevisao');
    }

    function permitirLancamento():boolean {
      if (UtilitarioGeral.validaLista(props.conferentes)) {
        if (props.conferentes.some((c) => c.codigoUsuario === storeSistema.getters.codigoUsuarioAutenticado())) {
          return true;
        }
      }
      return false;
    }

    onMounted(async () => {
      gradeBase.propriedadesConsulta = await servicoProduto.obterPropriedadesConsultaDefinicoes();
      await preenchePropriedadesNosFiltrosEOrdenacao();
      await defineValoresIniciaisGrade();
      await obterContagens();
    });

    async function removerContagem(itemBalanco: IDTOBalancoEstoqueItemContagem) {
      const retornoExclusao = await servicoBalancoEstoque.excluirContagem(props.codigoBalancoEstoque, props.codigoEmpresa, itemBalanco.codigoItemBalanco, itemBalanco.codigo, itemBalanco.produto);
      if (retornoExclusao.status === EStatusRetornoRequisicao.Sucesso) {
        const index = state.itens.findIndex((c) => c.codigo === itemBalanco.codigo);
        if (index !== -1) {
          state.itens.splice(index, 1);
        }
        emit('atualizarRevisao');
        apresentarMensagemSucesso(retornoExclusao.mensagem);
      } else {
        apresentarMensagemAlerta(retornoExclusao.mensagem);
      }
    }

    function confirmaExclusao(itemBalanco: IDTOBalancoEstoqueItemContagem) {
      Modal.confirm({
        title: 'Você deseja a contagem do produto:',
        content: `${itemBalanco.produto} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerContagem(itemBalanco); },
      });
    }

    function editarContagem(contagem:IDTOBalancoEstoqueItemContagem) {
      if (refBalancoEstoqueContagem.value !== undefined) {
        refBalancoEstoqueContagem.value.editarContagem(UtilitarioGeral.instanciaObjetoLocal(contagem));
      }
    }

    function verificaApresentacaoItens() {
      if (state.modoApresentacaoProdutos === EApresentarProdutosContados.UltimosLancamentos
      || state.modoApresentacaoProdutos === EApresentarProdutosContados.TodosProdutos) {
        return true;
      }

      if (state.modoApresentacaoProdutos === EApresentarProdutosContados.BuscaAvancada && gradeBase.buscaAvancada.filtrosAdicionados.length <= 0) {
        return false;
      }

      return true;
    }

    function salvarRastreabilidadeItem(index: number) {
      return '';
    }

    async function verificaAlteracaoApresentacaoItens() {
      state.itens = [];
      await obterContagens();
    }

    function removerArquivo(file:never) {
      const index = state.listaArquivos.indexOf(file);
      const newFileList = state.listaArquivos.slice();
      newFileList.splice(index, 1);
      state.listaArquivos = newFileList;
    }

    async function importarArquivoBalanco() {
      if (!UtilitarioGeral.validaCodigo(state.codigoEstoqueImportacao)) {
        apresentarMensagemAlerta('É necessário selecionar um estoque para realizar a importação!');
        return;
      }

      if (!UtilitarioGeral.objetoValido(state.listaArquivos)) {
        apresentarMensagemAlerta('É necessário selecionar um arquivo para realizar a importação!');
        return;
      }

      state.importacaoArquivoEmAndamento = true;
      const retornoImportacao = await servicoImportacaoEstoques.importarBalancoEstoque(props.codigoBalancoEstoque, state.codigoEstoqueImportacao, arquivoUpload);
      state.importacaoArquivoEmAndamento = false;

      if (retornoImportacao.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Importação concluída com sucesso!');
        state.codigoEstoqueImportacao = 0;
        state.listaArquivos = [];

        state.modoApresentacaoProdutos = EApresentarProdutosContados.UltimosLancamentos;
        await obterContagens();
      } else {
        apresentarMensagemAlerta(retornoImportacao.mensagem);
      }

      if (retornoImportacao.mensagem.includes('inconsistências')) {
        state.resumoImportacaoArquivo = retornoImportacao.mensagem;
      }
    }

    function prepararArquivoUpload(arquivo:any) {
      arquivoUpload = new FormData();
      arquivoUpload.append('arquivo', arquivo);
    }

    async function baixarModeloImportacaoArquivo(preencherProdutos?:boolean) {
      const arquivo = await servicoImportacaoEstoques.obterArquivoPadraoBalancoEstoque(props.codigoEmpresa, preencherProdutos);
      if (UtilitarioGeral.valorValido(arquivo)) {
        UtilitarioGeral.downloadArquivo(arquivo);
      } else {
        apresentarMensagemAlerta('Não foi possível localizar o arquivo de modelo, entre em contato com nosso suporte.');
      }
    }

    function lancarRastreabilidade(index: number) {
      // if (computedItens.value[index].tipoRastreabilidade !== ETipoRastreabilidade.Nenhum) {
      //   state.indexItemSelecionado = index;
      //   state.codigoItemSelecionado = computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao;
      //   state.codigoItemEstoqueSelecioando = computedItens.value[index].itemNotaFiscal.estoques[0].codigoEstoqueItem;
      //   state.quantidadeSelecionado = computedItens.value[index].itemNotaFiscal.quantidade;
      //   state.rastreabilidades = computedItens.value[index].rastreabilidades;
      //   state.exibirLancamentoRastreabilidade = true;
      // }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      refBalancoEstoqueContagem,
      EApresentarProdutosContados,
      computedCodigoEmpresa,
      UtilitarioMascara,
      UtilitarioGeral,
      permitirLancamento,
      aplicarCasasDecimaisQuantidade,
      mudarQuantidadeRegistrosPorPagina,
      mudarPagina,
      aplicarFiltros,
      ordenacaoAtiva,
      preencheCodigosSelecionados,
      preencheOrdenacaoSelecionada,
      obtemLarguraGrade,
      alterarQuantidadeRegistroTotal,
      ordenarDados,
      defineParametrosConsultaNovosProdutos,
      apresentarSelecaoNovosProdutos,
      atualizarContagemDefinicao,
      confirmadaContagemEstoque,
      obterContagens,
      confirmaExclusao,
      editarContagem,
      verificaApresentacaoItens,
      verificaAlteracaoApresentacaoItens,
      removerArquivo,
      prepararArquivoUpload,
      importarArquivoBalanco,
      baixarModeloImportacaoArquivo,
      salvarRastreabilidadeItem,
      lancarRastreabilidade,
    };
  },
});
