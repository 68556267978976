import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-2" }
const _hoisted_8 = { class: "ant-row" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-2" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-2" }
const _hoisted_13 = { class: "ant-col ant-col-xs-24 ant-col-xl-2" }
const _hoisted_14 = { class: "ant-col ant-col-xs-24 ant-col-xl-2" }
const _hoisted_15 = { class: "ant-row" }
const _hoisted_16 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_selecionar_tipo_documento_financeiro = _resolveComponent("selecionar-tipo-documento-financeiro")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_form_item, {
            label: "Negativado SPC",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio_group, {
                value: _ctx.computedFinanceiro.negativacaoSpc,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedFinanceiro.negativacaoSpc) = $event)),
                "button-style": "solid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_radio_button, { value: false }, {
                    default: _withCtx(() => [
                      _createTextVNode("Não")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: true }, {
                    default: _withCtx(() => [
                      _createTextVNode("Sim")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form_item, {
            label: "Data consulta SPC",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_data, {
                horaMinutoSegundo: true,
                dataSelecionada: _ctx.computedFinanceiro.dataConsultaSpc,
                "onUpdate:dataSelecionada": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedFinanceiro.dataConsultaSpc) = $event))
              }, null, 8, ["dataSelecionada"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_form_item, {
            label: "Limite de crédito - Liberado",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                monetario: true,
                valor: _ctx.computedFinanceiro.limiteCredito,
                "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedFinanceiro.limiteCredito) = $event))
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_form_item, {
            label: "Limite de crédito - Saldo",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                monetario: true,
                valor: _ctx.computedFinanceiro.limiteCreditoSaldo,
                "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedFinanceiro.limiteCreditoSaldo) = $event)),
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_form_item, {
            label: "Protestar títulos",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.computedFinanceiro.protestarTitulos,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedFinanceiro.protestarTitulos) = $event)),
                placeholder: "Selecione",
                style: {"width":"100%"},
                options: _ctx.state.listaProtestarTitulos
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_a_form_item, {
            label: "Dias protesto",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "ant-input",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedFinanceiro.diasProtesto) = $event))
              }, null, 512), [
                [_vModelText, _ctx.computedFinanceiro.diasProtesto]
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_form_item, {
            label: "Bloquear movimentações",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio_group, {
                value: _ctx.computedFinanceiro.bloqueado,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedFinanceiro.bloqueado) = $event)),
                "button-style": "solid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_radio_button, { value: false }, {
                    default: _withCtx(() => [
                      _createTextVNode("Não")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: true }, {
                    default: _withCtx(() => [
                      _createTextVNode("Sim")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_a_form_item, {
            label: "Motivo bloqueio",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                maxlength: "250",
                class: "ant-input",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedFinanceiro.motivoBloqueio) = $event))
              }, null, 512), [
                [_vModelText, _ctx.computedFinanceiro.motivoBloqueio]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_a_form_item, {
            label: "Indíce",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                percentual: true,
                valor: _ctx.computedFinanceiro.indice,
                "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedFinanceiro.indice) = $event))
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_a_form_item, {
            label: "Juros",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                percentual: true,
                valor: _ctx.computedFinanceiro.juros,
                "onUpdate:valor": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedFinanceiro.juros) = $event))
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_a_form_item, {
            label: "Multa",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                percentual: true,
                valor: _ctx.computedFinanceiro.multa,
                "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedFinanceiro.multa) = $event))
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_a_form_item, {
            label: "Desconto",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                percentual: true,
                valor: _ctx.computedFinanceiro.desconto,
                "onUpdate:valor": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedFinanceiro.desconto) = $event))
              }, null, 8, ["valor"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_alert, {
        style: {"margin-top":"3px","margin-bottom":"3px"},
        description: "Os tipos de documentos informados abaixo serão os únicos tipos de documentos que o cliente poderá usar. Caso não houver nenhum tipo informado o cliente não será limitado.",
        type: "info",
        "show-icon": ""
      }),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_a_form_item, {
            label: "Tipos de Documentos",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_tipo_documento_financeiro, {
                varios: true,
                empresas: _ctx.props.empresas,
                "codigos-selecionados": _ctx.computedTiposDocumentos,
                "onUpdate:codigosSelecionados": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedTiposDocumentos) = $event))
              }, null, 8, ["empresas", "codigos-selecionados"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 64))
}