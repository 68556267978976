
import {
  defineComponent, reactive, ref, computed,
} from 'vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useModalBase } from '@/core/composables/ModalBase';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Icone from '@/core/components/Icone.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import BalancoEstoqueContagem from './BalancoEstoqueContagem.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import { IDTOBalancoEstoqueItemRevisao } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemRevisao';
import { IBalancoEstoqueConferente, IBalancoEstoqueItemRevisao } from '@/models/Entidades/Estoques/IBalancoEstoque';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IDTOBalancoEstoqueRevisaoEstatisticas } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueRevisaoEstatisticas';

export default defineComponent({
  name: 'BalancoEstoqueRevisao',
  props: {
    codigoBalancoEstoque: {
      type: Number,
      required: true,
    },
    codigoEmpresa: {
      type: Number,
      required: true,
    },
    codigoResponsavel: {
      type: Number,
      required: true,
    },
    conferentes: {
      type: Array as () => IBalancoEstoqueConferente[],
      required: true,
    },
  },
  components: {
    MensagemSemDados,
    RequisicaoModal,
    CampoNumerico,
    Icone,
    Totalizador,
  },
  emits: ['revisaoConcluida'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso,
      ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const chaveContagemUsuario = 'CONTAGEM-USUARIO-';

    const {
      gradeBase,
      preencheOrdenacaoSelecionada, ordenacaoAtiva, ordenarDados, mudarPagina, mudarQuantidadeRegistrosPorPagina,
      preencheCodigosSelecionados, alterarQuantidadeRegistroTotal, obtemLarguraGrade,
    } = useGradeBase();

    const servicoBalancoEstoque = new ServicoBalancoEstoque();
    const refBalancoEstoqueContagem = ref<InstanceType<typeof BalancoEstoqueContagem>>();

    enum EApresentarProdutosContados{
      UltimosLancamentos = 1,
      TodosProdutos = 2,
      BuscaAvancada = 3,
    }
    const state = reactive({
      itens: [] as IDTOBalancoEstoqueItemRevisao[],
      estatisticasRevisao: {} as IDTOBalancoEstoqueRevisaoEstatisticas,
      larguraGrade: 1200,
      conferentes: [] as IItemConsultaRapida[],
      exibirBuscaProduto: false,
    });

    function verificarSituacaoRevisao() {
      if (state.estatisticasRevisao.quantidadeItens > 0 && state.estatisticasRevisao.quantidadeItens === state.estatisticasRevisao.revisados) {
        emit('revisaoConcluida');
      }
    }

    function verificaPosicaoColuna():string | undefined {
      if (UtilitarioDispositivo.larguraTelaMobile()) {
        return undefined;
      }
      return 'left';
    }
    async function montaGradeProdutos() {
      gradeBase.colunas = [
        {
          title: 'Código Definição', dataIndex: 'codigoDefinicao', key: 'CodigoDefinicao', position: 0, visible: false, ordering: false,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 60, ellipsis: true, position: 1, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'right',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 250, ellipsis: true, position: 2, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'left',
        },
        {
          title: 'UN', dataIndex: 'sigla', key: 'UnidadeProduto', width: 30, ellipsis: true, position: 3, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'center',
        },
        {
          title: 'Estoque', dataIndex: 'estoque', key: 'estoque', width: 70, position: 4, visible: true, ordering: false, align: 'left',
        },
      ];

      let position = 5;
      let contagem = 1;
      if (UtilitarioGeral.validaLista(props.conferentes)) {
        state.conferentes = await new ServicoUsuario().consultaRapidaPorCodigos(props.conferentes.map((c) => c.codigoUsuario), [props.codigoEmpresa]);
        if (UtilitarioGeral.validaLista(state.conferentes)) {
          props.conferentes.forEach((conferente) => {
            const dadosUsario = state.conferentes.find((c) => c.codigo === conferente.codigoUsuario);
            if (dadosUsario !== undefined) {
              const colunaQuantidadeConferente:IColumn = {} as IColumn;
              colunaQuantidadeConferente.title = `${contagem}ª Contagem - ${dadosUsario.textoIdentificacao}`;
              colunaQuantidadeConferente.key = `${chaveContagemUsuario}${conferente.codigoUsuario}`;
              colunaQuantidadeConferente.visible = true;
              colunaQuantidadeConferente.position = position;
              colunaQuantidadeConferente.width = 120;
              colunaQuantidadeConferente.ellipsis = true;
              colunaQuantidadeConferente.align = 'right';
              position += 1;
              contagem += 1;
              gradeBase.colunas.push(colunaQuantidadeConferente);
              state.larguraGrade += 120;
            }
          });
        }
      }

      const colunaObservacoes:IColumn = {} as IColumn;
      colunaObservacoes.title = 'Observações';
      colunaObservacoes.key = 'Observacoes';
      colunaObservacoes.visible = true;
      colunaObservacoes.position = position;
      colunaObservacoes.width = 200;
      colunaObservacoes.align = 'left';
      position += 1;
      contagem += 1;
      gradeBase.colunas.push(colunaObservacoes);

      const colunaQuantidadeRevisao:IColumn = {} as IColumn;
      colunaQuantidadeRevisao.title = 'Saldo Final';
      colunaQuantidadeRevisao.key = 'SaldoFinal';
      colunaQuantidadeRevisao.visible = true;
      colunaQuantidadeRevisao.position = position;
      colunaQuantidadeRevisao.width = 120;
      colunaQuantidadeRevisao.align = 'right';
      colunaQuantidadeRevisao.fixed = 'right';
      position += 1;
      contagem += 1;
      gradeBase.colunas.push(colunaQuantidadeRevisao);

      state.larguraGrade += 320;
    }

    state.itens = [];

    function obtemCodigoBalanco():number {
      montaGradeProdutos();
      return props.codigoBalancoEstoque;
    }
    const computedCodigoBalancoEstoque = computed(() => obtemCodigoBalanco());

    async function buscarDados() {
      state.itens = [];

      gradeBase.buscandoDados = true;
      if (props.codigoEmpresa === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      const revisoes = await servicoBalancoEstoque.obterRevisoes(props.codigoBalancoEstoque, props.codigoEmpresa);
      if (UtilitarioGeral.validaLista(revisoes)) {
        state.itens = revisoes;
      }

      state.estatisticasRevisao = await servicoBalancoEstoque.obterEstatisticasRevisao(props.codigoBalancoEstoque, props.codigoEmpresa);
      verificarSituacaoRevisao();
      gradeBase.buscandoDados = false;
    }

    function aplicarCasasDecimaisQuantidade(valor:number, quantidadeCasas: number):string {
      const precoFormatado = UtilitarioMascara.mascararValor(valor, quantidadeCasas);
      return precoFormatado;
    }

    function obtemQuantidadeContagem(itemRevisao:IDTOBalancoEstoqueItemRevisao, chaveContagem: string):number {
      const codigoConferente = Number(chaveContagem.replace(chaveContagemUsuario, ''));
      const itemContagem = itemRevisao.contagens.find((c) => c.codigoUsuario === codigoConferente);

      if (itemContagem !== undefined) {
        return itemContagem.quantidadeContada;
      }

      return 0;
    }

    function obtemSomaTodasContagens(itemRevisao:IDTOBalancoEstoqueItemRevisao):number {
      let quantidadesContadas = 0;
      if (UtilitarioGeral.validaLista(itemRevisao.contagens)) {
        itemRevisao.contagens.forEach((contagem) => {
          quantidadesContadas += contagem.quantidadeContada;
        });
      }
      return quantidadesContadas;
    }

    async function sincronizarInformacoesRevisao(codigoRevisao:number, codigoItemBalanco:number, quantidadeRevisada:number, observacoes: string) {
      let retornoRevisao:IRetornoRequisicao = {} as IRetornoRequisicao;
      const revisao:IBalancoEstoqueItemRevisao = {} as IBalancoEstoqueItemRevisao;
      let atualizarEstatisticas = false;
      revisao.codigo = codigoRevisao;
      revisao.codigoBalancoEstoqueItem = codigoItemBalanco;
      revisao.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      revisao.quantidadeRevisada = quantidadeRevisada;
      revisao.observacoes = observacoes;

      if (codigoRevisao > 0) {
        retornoRevisao = await servicoBalancoEstoque.atualizarRevisao(props.codigoBalancoEstoque, props.codigoEmpresa, revisao);
      } else {
        retornoRevisao = await servicoBalancoEstoque.lancarRevisao(props.codigoBalancoEstoque, props.codigoEmpresa, revisao);
        atualizarEstatisticas = true;
      }

      if (retornoRevisao.status === EStatusRetornoRequisicao.Sucesso) {
        const indice = state.itens.findIndex((c) => c.codigoItemBalanco === codigoItemBalanco);
        if (indice >= 0) {
          state.itens[indice].codigo = retornoRevisao.codigoRegistro;
          state.itens[indice].quantidadeRevisada = quantidadeRevisada;
        }

        if (atualizarEstatisticas) {
          state.estatisticasRevisao.revisados += 1;
          state.estatisticasRevisao.aguardandoRevisao -= 1;
          verificarSituacaoRevisao();
        }
      } else {
        apresentarMensagemAlerta(retornoRevisao.mensagem);
      }
    }

    async function defineSaldoFinalComSomaTodasContagens() {
      let retornoRevisao:IRetornoRequisicao = {} as IRetornoRequisicao;

      const revisoes:IBalancoEstoqueItemRevisao[] = [];
      const dataRevisao = await new ServicoSistema().obterDataAtual();
      state.itens.forEach((item) => {
        const revisao:IBalancoEstoqueItemRevisao = {} as IBalancoEstoqueItemRevisao;
        revisao.codigo = item.codigo;
        revisao.codigoBalancoEstoqueItem = item.codigoItemBalanco;
        revisao.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
        revisao.dataRevisao = dataRevisao;
        revisao.quantidadeRevisada = 0;
        item.contagens.forEach((contagem) => {
          revisao.quantidadeRevisada += contagem.quantidadeContada;
        });
        revisao.observacoes = item.observacoes;
        revisoes.push(revisao);
      });

      apresentarBarraProgresso();
      retornoRevisao = await servicoBalancoEstoque.definirSaldoFinalRevisoes(props.codigoBalancoEstoque, props.codigoEmpresa, revisoes);

      if (retornoRevisao.status === EStatusRetornoRequisicao.Sucesso) {
        await buscarDados();
        ocultarBarraProgresso();
      } else if (retornoRevisao.status === EStatusRetornoRequisicao.Alerta) {
        ocultarBarraProgresso();
        apresentarRetornoRequisicao(retornoRevisao);
      } else {
        ocultarBarraProgresso();
        apresentarMensagemAlerta(retornoRevisao.mensagem);
      }
    }

    async function defineSaldoFinalContagemEspecifica(codigoConferente:number) {
      let retornoRevisao:IRetornoRequisicao = {} as IRetornoRequisicao;

      const revisoes:IBalancoEstoqueItemRevisao[] = [];
      const dataRevisao = await new ServicoSistema().obterDataAtual();
      state.itens.forEach((item) => {
        const revisao:IBalancoEstoqueItemRevisao = {} as IBalancoEstoqueItemRevisao;
        revisao.codigo = item.codigo;
        revisao.codigoBalancoEstoqueItem = item.codigoItemBalanco;
        revisao.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
        revisao.dataRevisao = dataRevisao;
        revisao.quantidadeRevisada = 0;
        const contagem = item.contagens.find((c) => c.codigoUsuario === codigoConferente);
        if (contagem !== null && contagem !== undefined) {
          revisao.quantidadeRevisada = contagem.quantidadeContada;
        }
        revisao.observacoes = item.observacoes;
        revisoes.push(revisao);
      });

      apresentarBarraProgresso();
      retornoRevisao = await servicoBalancoEstoque.definirSaldoFinalRevisoes(props.codigoBalancoEstoque, props.codigoEmpresa, revisoes);

      if (retornoRevisao.status === EStatusRetornoRequisicao.Sucesso) {
        await buscarDados();
        ocultarBarraProgresso();
        apresentarMensagemSucesso('Informações atualizadas com sucesso!');
      } else if (retornoRevisao.status === EStatusRetornoRequisicao.Alerta) {
        ocultarBarraProgresso();
        apresentarRetornoRequisicao(retornoRevisao);
      } else {
        ocultarBarraProgresso();
        apresentarMensagemAlerta(retornoRevisao.mensagem);
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      computedCodigoBalancoEstoque,
      refBalancoEstoqueContagem,
      EApresentarProdutosContados,
      chaveContagemUsuario,
      obtemQuantidadeContagem,
      UtilitarioMascara,
      buscarDados,
      aplicarCasasDecimaisQuantidade,
      mudarQuantidadeRegistrosPorPagina,
      mudarPagina,
      ordenacaoAtiva,
      preencheCodigosSelecionados,
      preencheOrdenacaoSelecionada,
      obtemLarguraGrade,
      alterarQuantidadeRegistroTotal,
      ordenarDados,
      sincronizarInformacoesRevisao,
      obtemSomaTodasContagens,
      defineSaldoFinalComSomaTodasContagens,
      defineSaldoFinalContagemEspecifica,
    };
  },
});
