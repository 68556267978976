import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IDTOMovimentoRastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTOMovimentoRastreabilidade';
import { IDTORastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTORastreabilidade';
import {
  IRastreabilidade,
} from '@/models/Entidades/Estoques/IRastreabilidade';
import { IParametroConsultaRastreabilidade } from '@/models/ParametrosRequisicao/Estoques/IParametroConsultaRastreabilidade';

/**
 * Serviço Rastreabilidade
 * Fornece todas regras de negócios e lógicas relacionado a Rastreabilidade.
 */
class ServicoRastreabilidade implements IServicoBase<IRastreabilidade> {
    endPoint = 'rastreabilidades';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      const listaPaginada = await this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
      return listaPaginada;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros);
    }

    public async relatorioMovimentacoes(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}/impressao/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IRastreabilidade): string {
      return objeto.codigo.toString();
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IRastreabilidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IRastreabilidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IRastreabilidade> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterMovimentacoes(codigo: number, empresa: number) : Promise<IDTOMovimentoRastreabilidade[]> {
      const movimentos: any = await this.apiERP.get(`${this.endPoint}/obter-movimentacoes/${empresa}/${codigo}`);
      return movimentos.data;
    }

    public async excluirOrigem(movimento: number, recurso: string) : Promise<IRetornoRequisicao> {
      const movimentos: any = await this.apiERP.get(`${this.endPoint}/excluir-origem/${movimento}/${recurso}`);
      return movimentos.data;
    }

    public async obterRastreabilidadesProduto(parametros: IParametroConsultaRastreabilidade) : Promise<IDTORastreabilidade[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/obter-rastreabilidades`, parametros);
      return result.data;
    }

    public async lancarRastreabilidadeSaida(codigo: number, recurso: string, rastreabilidades: IRastreabilidade[]) : Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/lancar-rastreabilidade-venda/${codigo}/${recurso}`, rastreabilidades);
      return result.data;
    }
}
export default ServicoRastreabilidade;
