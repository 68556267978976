import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ss-tamanho-completo",
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: 1 }, {
          default: _withCtx(() => [
            _createTextVNode("Uso Permitido")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 2 }, {
          default: _withCtx(() => [
            _createTextVNode("Uso Restrito")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange", "disabled"])
  ]))
}