
import {
  computed,
  defineComponent,
  reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import DadosPrincipaisPessoa from '@/components/Cadastros/Pessoas/DadosPrincipaisPessoa.vue';
import DadosPessoais from '@/components/Cadastros/Pessoas/DadosPessoais.vue';
import EnderecosPessoa from '@/components/Cadastros/Pessoas/EnderecosPessoa.vue';
import ContatosPessoa from '@/components/Cadastros/Pessoas/ContatosPessoa.vue';
import AnexosPessoa from '@/components/Cadastros/Pessoas/AnexosPessoa.vue';
import InscricoesPessoa from '@/components/Cadastros/Pessoas/InscricoesPessoa.vue';
import FiscalTributacaoPessoa from '@/components/Cadastros/Pessoas/FiscalTributacaoPessoa.vue';
import DadosFinanceiroCliente from '@/components/Cadastros/Pessoas/Clientes/DadosFinanceiroCliente.vue';
import DadosColaborador from '@/components/Cadastros/Pessoas/Colaboradores/DadosColaborador.vue';
import { IPessoa } from '@/models/Entidades/Cadastros/Pessoas/IPessoa';
import { IPessoaJuridica } from '@/models/Entidades/Cadastros/Pessoas/IPessoaJuridica';
import { IPessoaFisica } from '@/models/Entidades/Cadastros/Pessoas/IPessoaFisica';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { IPessoaInscricoes } from '@/models/Entidades/Cadastros/Pessoas/IPessoaInscricoes';
import { IPessoaFiscal } from '@/models/Entidades/Cadastros/Pessoas/IPessoaFiscal';
import { IPessoaContato } from '@/models/Entidades/Cadastros/Pessoas/IPessoaContato';
import { IPessoaAnexo } from '@/models/Entidades/Cadastros/Pessoas/IPessoaAnexo';
import { IPessoaDadosBancarios } from '@/models/Entidades/Cadastros/Pessoas/IPessoaDadosBancarios';
import Card from '@/core/components/Tela/Card.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarMarcador from '@/components/Cadastros/Pessoas/SelecionarMarcador.vue';
import SelecionarRelacaoPessoa from '@/components/Cadastros/Pessoas/SelecionarRelacaoPessoa.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import { IPessoaPlanoContaCategoria } from '@/models/Entidades/Cadastros/Pessoas/IPessoaPlanoContaCategoria';
import { IPessoaEmpresa } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEmpresa';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ICliente, IClienteTipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Pessoas/Clientes/ICliente';
import { IClienteFinanceiro } from '@/models/Entidades/Cadastros/Pessoas/Clientes/IClienteFinanceiro';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useModalBase } from '@/core/composables/ModalBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IPessoaRelacao } from '@/models/Entidades/Cadastros/Pessoas/IPessoaRelacao';
import { IPessoaMarcador } from '@/models/Entidades/Cadastros/Pessoas/IPessoaMarcador';
import { IFornecedor } from '@/models/Entidades/Cadastros/Pessoas/Fornecedores/IFornecedor';
import { ITransportadora } from '@/models/Entidades/Cadastros/Pessoas/Transportadoras/ITransportadora';
import { IRepresentante } from '@/models/Entidades/Cadastros/Pessoas/Representantes/IRepresentante';
import { IColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IColaborador';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOColaboradorDepartamentoFuncao } from '@/models/DTO/Cadastros/Pessoas/IDTOColaboradorDepartamentoFuncao';
import { IColaboradorFuncaoColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IColaboradorFuncaoColaborador';
import { IColaboradorDepartamentoColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IColaboradorDepartamentoColaborador';
import { IDTORetornoConsultaCNPJ } from '@/models/DTO/Integracoes/IDTORetornoConsultaCNPJ';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import { ETipoInscricaoEstadual } from '@/models/Enumeradores/Compartilhados/ETipoInscricaoEstadual';
import { ETipoEndereco } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoEndereco';
import DisplayTelaPersonalizada from '@/components/MeuSistema/PersonalizacoesTelas/DisplayTelaPersonalizada.vue';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import { ICampoPersonalizado, IPersonalizacaoTela } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import DadosBancariosPessoa from '@/components/Cadastros/Pessoas/DadosBancariosPessoa.vue';

export default defineComponent({
  name: 'PessoaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    tituloModal: {
      type: String,
      required: true,
    },
    relacaoPrincipalOperacao: {
      type: Number,
      required: true,
    },
    servicoAPI: {
      type: Object as () => IServicoBase<IPessoa>,
      required: true,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    pessoaPreCadastro: {
      type: Object as () => IPessoa,
    },
  },
  components: {
    Card,
    Icone,
    EditorHtml,
    SelecionarMarcador,
    SelecionarRelacaoPessoa,
    SelecionarEmpresaCadastroCompartilhado,
    DadosPrincipaisPessoa,
    DadosPessoais,
    EnderecosPessoa,
    ContatosPessoa,
    AnexosPessoa,
    InscricoesPessoa,
    FiscalTributacaoPessoa,
    DadosFinanceiroCliente,
    DadosColaborador,
    RequisicaoModal,
    DisplayTelaPersonalizada,
    DadosBancariosPessoa,
  },
  emits: ['sincronizarRegistro', 'cadastroConcluido', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, defineEmpresasSelecionadasCadastroCompartilhado,
      preencherPermissoesDados, verificaConceitoParaApresentarEmpresas, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
      apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro, defineTextoPadraoBotoes,
    } = useModalBase(props, emit);
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();
    servicoPersonalizacaoTela.requisicaoSistema();
    const servicoPessoa = new ServicoPessoa();
    const servicoUtilitario = new ServicoUtilitario();
    servicoPessoa.requisicaoSistema();

    switch (props.relacaoPrincipalOperacao) {
      case ETipoRelacaoPessoa.Colaborador:
        telaBase.identificadorRecurso = 'CADASTRO_COLABORADORES';
        telaBase.identificadorPermissao = 'PER_CADASTRO_COLABORADORES';
        break;
      case ETipoRelacaoPessoa.Cliente:
        telaBase.identificadorRecurso = 'CADASTRO_CLIENTES';
        telaBase.identificadorPermissao = 'PER_CADASTRO_CLIENTES';
        break;
      case ETipoRelacaoPessoa.Fornecedor:
        telaBase.identificadorRecurso = 'CADASTRO_FORNECEDORES';
        telaBase.identificadorPermissao = 'PER_CADASTRO_FORNECEDORES';
        break;
      case ETipoRelacaoPessoa.Transportadora:
        telaBase.identificadorRecurso = 'CADASTRO_TRANSPORTADORAS';
        telaBase.identificadorPermissao = 'PER_CADASTRO_TRANSPORTADORAS';
        break;
      case ETipoRelacaoPessoa.Representante:
        telaBase.identificadorRecurso = 'CADASTRO_REPRESENTANTES';
        telaBase.identificadorPermissao = 'PER_CADASTRO_REPRESENTANTES';
        break;
      default:
    }

    const state = reactive({
      pessoa: {} as IPessoa,
      marcadoresSelecionados: [] as string[],
      relacoesSelecionadas: [] as number[],
      departamentoFuncaoColaborador: [] as IDTOColaboradorDepartamentoFuncao[],
      tiposDocumentos: [] as number[],
      windowWidth: window.innerWidth,
      personalizacaoTela: {} as IPersonalizacaoTela,
      exibirCamposPersonalizados: false,
      reaproveitadoCadastro: false,
      consultandoCpfCnpj: false,
    });

    const computedOperacao = computed({
      get: () => props.operacao,
      set: (operacao: ITelaOperacao) => {
        emit('update:operacao', operacao);
      },
    });

    async function verificarUsoCpf() {
      if (state.consultandoCpfCnpj) return;
      if (state.pessoa.codigo > 0) return;
      state.consultandoCpfCnpj = true;
      let valido = false;
      let cpfCnpj = '';
      let descricaoCpfCnpj = '';

      if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
        if (state.pessoa.pessoaFisica.cpf === '' || state.pessoa.pessoaFisica.cpf === undefined || state.pessoa.pessoaFisica.cpf.length < 11) {
          state.consultandoCpfCnpj = false;
          return;
        }
        cpfCnpj = state.pessoa.pessoaFisica.cpf;
        descricaoCpfCnpj = 'CPF';
        const retornoValidacao = await servicoUtilitario.validarCpfCnpj(cpfCnpj);
        valido = retornoValidacao.sucesso;
      } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
        if (state.pessoa.pessoaJuridica.cnpj === '' || state.pessoa.pessoaJuridica.cnpj === undefined || state.pessoa.pessoaJuridica.cnpj.length < 14) {
          state.consultandoCpfCnpj = false;
          return;
        }
        cpfCnpj = state.pessoa.pessoaJuridica.cnpj;
        descricaoCpfCnpj = 'CNPJ';
        const retornoValidacao = await servicoUtilitario.validarCpfCnpj(cpfCnpj);
        valido = retornoValidacao.sucesso;
      } else {
        if (state.pessoa.pessoaEstrangeira.documentoIdentificacao === '') {
          state.consultandoCpfCnpj = false;
          return;
        }
        valido = true;
        cpfCnpj = state.pessoa.pessoaEstrangeira.documentoIdentificacao;
        descricaoCpfCnpj = 'Documento de identificação';
      }
      if (valido) {
        const relacoes = await servicoPessoa.obterRelacoesCpfCnpj(state.pessoa.codigo, cpfCnpj, state.pessoa.tipoPessoa, telaBase.empresasSelecionadas);
        if (relacoes.length > 0) {
          let emUso = false;
          relacoes.forEach((r) => {
            if (r === props.relacaoPrincipalOperacao) {
              apresentarMensagemAlerta(`Já existe um ${servicoPessoa.obterDescricaoRelacao(r)} cadastrado com esse ${descricaoCpfCnpj}`);
              if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
                state.pessoa.pessoaFisica.cpf = '';
              } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
                state.pessoa.pessoaJuridica.cnpj = '';
              } else {
                state.pessoa.pessoaEstrangeira.documentoIdentificacao = '';
              }
              emUso = true;
            }
          });
          if (emUso) {
            state.consultandoCpfCnpj = false;
            return;
          }

          let mensagem = `O ${descricaoCpfCnpj} informado já foi usado `;
          if (relacoes.length === 1) {
            mensagem += `no cadastro de ${servicoPessoa.obterDescricaoRelacao(relacoes[0])}`;
          } else {
            mensagem += 'nos cadastros de ';
            for (let i = 0; i < relacoes.length - 1; i += 1) {
              mensagem += `${servicoPessoa.obterDescricaoRelacao(relacoes[0])}, `;
            }
            mensagem += `${servicoPessoa.obterDescricaoRelacao(relacoes[relacoes.length - 1])}.`;
          }
          Modal.confirm({
            title: mensagem,
            content: 'Deseja adicionar essa nova relação ao cadastro já existente?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            autoFocusButton: null,
            onOk: async () => {
              state.pessoa = await servicoPessoa.obterPeloCpfCnpj(props.operacao.empresaSelecionada, cpfCnpj, state.pessoa.tipoPessoa, props.relacaoPrincipalOperacao);
              const telefone = state.pessoa.telefone;
              state.pessoa.telefone = '';
              state.pessoa.telefone = telefone;
              state.relacoesSelecionadas = relacoes;
              state.relacoesSelecionadas.push(props.relacaoPrincipalOperacao);
              computedOperacao.value.tipoPermissaoDados = EPermissaoDados.Visualizar;
              state.reaproveitadoCadastro = true;
            },
            onCancel: () => {
              if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
                state.pessoa.pessoaFisica.cpf = '';
              } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
                state.pessoa.pessoaJuridica.cnpj = '';
              } else {
                state.pessoa.pessoaEstrangeira.documentoIdentificacao = '';
              }
            },
          });
        }
      } else if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
        apresentarMensagemAlerta('CPF inválido!');
        state.pessoa.pessoaFisica.cpf = '';
      } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
        apresentarMensagemAlerta('CNPJ inválido!');
        state.pessoa.pessoaJuridica.cnpj = '';
      } else {
        apresentarMensagemAlerta('Número do documento inválido!');
        state.pessoa.pessoaEstrangeira.documentoIdentificacao = '';
      }
      state.consultandoCpfCnpj = false;
    }

    async function verificarUsoNome() {
      if (state.pessoa.codigo > 0) return;
      let nome = '';
      let descricaoNome = 'nome';

      if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
        if (state.pessoa.pessoaFisica.nomeCompleto === '') {
          return;
        }
        nome = state.pessoa.pessoaFisica.nomeCompleto;
      } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
        if (state.pessoa.pessoaJuridica.razaoSocial === '') {
          return;
        }
        nome = state.pessoa.pessoaJuridica.razaoSocial;
        descricaoNome = 'razão social';
      } else {
        if (state.pessoa.pessoaEstrangeira.nomeCompleto === '') {
          return;
        }
        nome = state.pessoa.pessoaEstrangeira.nomeCompleto;
      }

      const relacoes = await servicoPessoa.obterRelacoesNome(state.pessoa.codigo, nome, state.pessoa.tipoPessoa, telaBase.empresasSelecionadas);
      if (relacoes.length > 0) {
        let emUso = false;
        relacoes.forEach((r) => {
          if (r === props.relacaoPrincipalOperacao) {
            apresentarMensagemAlerta(`Já existe um ${servicoPessoa.obterDescricaoRelacao(r)} cadastrado com esse ${descricaoNome}`);
            emUso = true;
          }
        });
        if (emUso) return;

        let mensagem = '';

        if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
          mensagem = 'A razão social informada já foi usada ';
        } else {
          mensagem = 'O nome informado já foi usado ';
        }

        if (relacoes.length === 1) {
          mensagem += `no cadastro de ${servicoPessoa.obterDescricaoRelacao(relacoes[0])}`;
        } else {
          mensagem += 'nos cadastros de ';
          for (let i = 0; i < relacoes.length - 1; i += 1) {
            mensagem += `${servicoPessoa.obterDescricaoRelacao(relacoes[0])}, `;
          }
          mensagem += `${servicoPessoa.obterDescricaoRelacao(relacoes[relacoes.length - 1])}.`;
        }

        Modal.confirm({
          title: mensagem,
          content: 'Deseja adicionar essa nova relação ao cadastro já existente?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: async () => {
            state.pessoa = await servicoPessoa.obterPeloNome(props.operacao.empresaSelecionada, nome, state.pessoa.tipoPessoa, props.relacaoPrincipalOperacao);
            const telefone = state.pessoa.telefone;
            state.pessoa.telefone = '';
            state.pessoa.telefone = telefone;
            state.relacoesSelecionadas = relacoes;
            state.relacoesSelecionadas.push(props.relacaoPrincipalOperacao);
            computedOperacao.value.tipoPermissaoDados = EPermissaoDados.Visualizar;
            state.reaproveitadoCadastro = true;
          },
          onCancel: () => {
            if (state.pessoa.tipoPessoa === ETipoPessoa.Fisica) {
              state.pessoa.pessoaFisica.nomeCompleto = '';
            } else if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica) {
              state.pessoa.pessoaJuridica.razaoSocial = '';
            } else {
              state.pessoa.pessoaEstrangeira.nomeCompleto = '';
            }
          },
        });
      }
    }

    function verificaApresentacaoColaborador(): boolean {
      if (state.relacoesSelecionadas.some((c) => c === ETipoRelacaoPessoa.Colaborador)) {
        return true;
      }
      return false;
    }

    function verificaApresentacaoFiscalInscricoes(): boolean {
      if (state.relacoesSelecionadas.some((c) => c === ETipoRelacaoPessoa.Cliente)) {
        return true;
      }
      if (state.relacoesSelecionadas.some((c) => c === ETipoRelacaoPessoa.Fornecedor)) {
        return true;
      }
      if (state.relacoesSelecionadas.some((c) => c === ETipoRelacaoPessoa.Transportadora)) {
        return true;
      }
      if (state.relacoesSelecionadas.some((c) => c === ETipoRelacaoPessoa.Representante)) {
        return true;
      }

      return false;
    }

    async function obterPersonalizacaoTela() {
      state.personalizacaoTela = await servicoPersonalizacaoTela.obterPeloRecurso(telaBase.identificadorRecurso, storeSistema.getters.codigoEmpresaOperacao());
      state.exibirCamposPersonalizados = UtilitarioGeral.valorValido(state.personalizacaoTela);
    }

    function prepararCategoriasPlanoContas() {
      const listaCategoriasPlanoContas: IPessoaPlanoContaCategoria[] = [];
      if (UtilitarioGeral.validaLista(telaBase.empresasSelecionadas) && UtilitarioGeral.validaLista(state.relacoesSelecionadas)) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          state.relacoesSelecionadas.forEach((tipoRelacao) => {
            const pessoaPlanoContaCategoria = {} as IPessoaPlanoContaCategoria;
            pessoaPlanoContaCategoria.codigo = 0;
            pessoaPlanoContaCategoria.codigoPessoa = state.pessoa.codigo;
            pessoaPlanoContaCategoria.codigoEmpresa = codigoEmpresa;
            pessoaPlanoContaCategoria.tipoRelacao = tipoRelacao;
            listaCategoriasPlanoContas.push(pessoaPlanoContaCategoria);
          });
        });

        for (let i = 0; i < listaCategoriasPlanoContas.length; (i += 1)) {
          const pessoaPlanoContaCategoria = state.pessoa.categoriasPlanoContas.find((c) => c.codigoEmpresa === listaCategoriasPlanoContas[i].codigoEmpresa && c.tipoRelacao === listaCategoriasPlanoContas[i].tipoRelacao);
          if (pessoaPlanoContaCategoria !== undefined) {
            listaCategoriasPlanoContas[i].codigo = pessoaPlanoContaCategoria.codigo;
            listaCategoriasPlanoContas[i].codigoPlanoContaCategoria = pessoaPlanoContaCategoria.codigoPlanoContaCategoria;
          }
        }
      }

      state.pessoa.categoriasPlanoContas = listaCategoriasPlanoContas;
    }

    function preparaDefinicaoDepartamentoFuncao(objetoPrincipal: boolean) {
      const listaDepartamentos: IDTOColaboradorDepartamentoFuncao[] = [];

      if (UtilitarioGeral.validaLista(telaBase.empresasSelecionadas) && verificaApresentacaoColaborador()) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const departamentoFuncaoColaborador: IDTOColaboradorDepartamentoFuncao = {} as IDTOColaboradorDepartamentoFuncao;
          departamentoFuncaoColaborador.codigoEmpresa = codigoEmpresa;
          listaDepartamentos.push(departamentoFuncaoColaborador);
        });

        if (objetoPrincipal) {
          for (let i = 0; i < listaDepartamentos.length; (i += 1)) {
            const funcaoColaborador = state.pessoa.colaborador.funcoes.find((c) => c.codigoEmpresa === listaDepartamentos[i].codigoEmpresa);
            if (funcaoColaborador !== undefined) {
              listaDepartamentos[i].codigoFuncao = funcaoColaborador.codigoFuncaoColaborador;
            }
            const departamentoColaborador = state.pessoa.colaborador.departamentos.find((c) => c.codigoEmpresa === listaDepartamentos[i].codigoEmpresa);
            if (departamentoColaborador !== undefined) {
              listaDepartamentos[i].codigoDepartamento = departamentoColaborador.codigoDepartamentoColaborador;
            }
          }
        } else {
          for (let i = 0; i < listaDepartamentos.length; (i += 1)) {
            const departamentoFuncaoColaborador = state.departamentoFuncaoColaborador.find((c) => c.codigoEmpresa === listaDepartamentos[i].codigoEmpresa);
            if (departamentoFuncaoColaborador !== undefined) {
              listaDepartamentos[i].codigoDepartamento = departamentoFuncaoColaborador.codigoDepartamento;
              listaDepartamentos[i].codigoFuncao = departamentoFuncaoColaborador.codigoFuncao;
            }
          }
        }
      }

      state.departamentoFuncaoColaborador = listaDepartamentos;
    }

    function estrategiaAlteracaoRelacao() {
      prepararCategoriasPlanoContas();
      preparaDefinicaoDepartamentoFuncao(false);
    }

    function estrategiaAlteracaoEmpresa() {
      prepararCategoriasPlanoContas();
      preparaDefinicaoDepartamentoFuncao(false);
    }
    function objetoInicial() {
      telaBase.empresasSelecionadas = [];
      state.marcadoresSelecionados = [];
      state.relacoesSelecionadas = [];
      state.tiposDocumentos = [];
      state.departamentoFuncaoColaborador = [];
      const pessoa = {} as IPessoa;
      pessoa.codigo = 0;
      pessoa.ativo = true;
      pessoa.tipoPessoa = ETipoPessoa.Fisica;
      pessoa.pessoaJuridica = {} as IPessoaJuridica;
      pessoa.pessoaFisica = {} as IPessoaFisica;
      pessoa.pessoaFisica.estadoCivil = 1;
      pessoa.pessoaFisica.categoriaCnh = 1;
      pessoa.inscricoes = [] as IPessoaInscricoes[];
      pessoa.fiscal = {} as IPessoaFiscal;
      pessoa.enderecos = [] as IPessoaEndereco[];
      pessoa.contatos = [] as IPessoaContato[];
      pessoa.anexos = [] as IPessoaAnexo[];
      pessoa.categoriasPlanoContas = [] as IPessoaPlanoContaCategoria[];
      pessoa.empresas = [] as IPessoaEmpresa[];
      pessoa.relacoes = [] as IPessoaRelacao[];
      pessoa.colaborador = {} as IColaborador;
      pessoa.colaborador.funcoes = [];
      pessoa.colaborador.departamentos = [];
      pessoa.cliente = {} as ICliente;
      pessoa.cliente.financeiro = {} as IClienteFinanceiro;
      pessoa.cliente.financeiro.negativacaoSpc = false;
      pessoa.cliente.financeiro.bloqueado = false;
      pessoa.cliente.financeiro.protestarTitulos = 0;
      pessoa.transportadora = {} as ITransportadora;
      pessoa.fornecedor = {} as IFornecedor;
      pessoa.representante = {} as IRepresentante;
      pessoa.fiscal.tipoAtividade = 4;
      pessoa.dadosBancarios = {} as IPessoaDadosBancarios;
      pessoa.dadosBancarios.codigoBanco = 0;
      pessoa.dadosBancarios.conta = '';
      pessoa.dadosBancarios.contaDv = '';
      pessoa.dadosBancarios.agencia = '';
      pessoa.dadosBancarios.agenciaDv = '';
      pessoa.dadosBancarios.chavePix = '';
      state.pessoa = pessoa;
      state.reaproveitadoCadastro = false;
    }

    async function obterCodigoCidade(nomeCidade: string, uf: string): Promise<number> {
      let codigoCidade = 0;

      const cidade = await new ServicoCidade().obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          codigoCidade = cidade.codigo;
        }
      }

      return codigoCidade;
    }

    async function atualizarDadosConsultaCnpj(retornoConsultaCnpj: IDTORetornoConsultaCNPJ) {
      state.pessoa.inscricoes = [] as IPessoaInscricoes[];
      if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
        if (UtilitarioGeral.objetoValido(retornoConsultaCnpj.inscricoes)) {
          retornoConsultaCnpj.inscricoes.forEach((i) => {
            const inscricoes: IPessoaInscricoes = {} as IPessoaInscricoes;
            inscricoes.tipoIe = i.inscricaoEstadual === '' ? ETipoInscricaoEstadual.NaoContribuinte : ETipoInscricaoEstadual.ContribuinteICMS;
            inscricoes.inscricaoEstadual = i.inscricaoEstadual;
            inscricoes.principal = i.principal;
            state.pessoa.inscricoes.push(inscricoes);
          });
        }

        if (UtilitarioGeral.objetoValido(retornoConsultaCnpj.simples)) {
          state.pessoa.fiscal.simplesNacional = retornoConsultaCnpj.simples.optante;
        }

        let criarNovoEndereco = false;
        let tipoEndereco = ETipoEndereco.Principal;
        if (UtilitarioGeral.valorValido(retornoConsultaCnpj.endereco.cidade)) {
          if (UtilitarioGeral.validaLista(state.pessoa.enderecos)) {
            const endereco = state.pessoa.enderecos.find((c) => c.endereco.cep === retornoConsultaCnpj.endereco.cep);
            if (endereco === undefined) {
              criarNovoEndereco = true;
            } else if (endereco.tipoEndereco === ETipoEndereco.Principal && endereco.endereco.logradouro !== retornoConsultaCnpj.endereco.logadouro) {
              criarNovoEndereco = true;
              tipoEndereco = ETipoEndereco.Outros;
            }
          } else {
            criarNovoEndereco = true;
          }

          if (criarNovoEndereco) {
            state.pessoa.enderecos = [] as IPessoaEndereco[];
            const novoEndereco: IPessoaEndereco = {} as IPessoaEndereco;
            novoEndereco.codigo = new Date().getTime();
            novoEndereco.codigoPessoa = state.pessoa.codigo;
            novoEndereco.codigoEndereco = 0;
            novoEndereco.tipoEndereco = tipoEndereco;
            novoEndereco.identificacao = 'Consulta Receita';
            novoEndereco.endereco = {} as IEndereco;
            novoEndereco.endereco.codigo = 0;
            novoEndereco.endereco.cep = retornoConsultaCnpj.endereco.cep;
            novoEndereco.endereco.logradouro = retornoConsultaCnpj.endereco.logadouro;
            novoEndereco.endereco.complemento = retornoConsultaCnpj.endereco.complemento;
            novoEndereco.endereco.bairro = retornoConsultaCnpj.endereco.bairro;
            novoEndereco.endereco.numero = retornoConsultaCnpj.endereco.numero;
            novoEndereco.endereco.cep = retornoConsultaCnpj.endereco.cep;
            novoEndereco.endereco.codigoCidade = await obterCodigoCidade(retornoConsultaCnpj.endereco.cidade, retornoConsultaCnpj.endereco.uf);
            state.pessoa.enderecos.push(novoEndereco);
          }
        }
      }
    }

    function preparaPersistenciaEmpresas() {
      const pessoaEmpresas: IPessoaEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.pessoa.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            pessoaEmpresas.push(empresaExistente);
          } else {
            const pessoaEmpresa: IPessoaEmpresa = { codigo: 0, codigoPessoa: state.pessoa.codigo, codigoEmpresa };
            pessoaEmpresas.push(pessoaEmpresa);
          }
        });
      }
      state.pessoa.empresas = pessoaEmpresas;
    }

    function preparaPersistenciaRelacoes() {
      const pessoaRelacoes: IPessoaRelacao[] = [];
      if (state.relacoesSelecionadas.length > 0) {
        state.relacoesSelecionadas.forEach((tipoRelacao) => {
          const relacaoExistente = state.pessoa.relacoes.find((c) => c.tipoRelacao === tipoRelacao);
          if (relacaoExistente !== undefined) {
            pessoaRelacoes.push(relacaoExistente);
          } else {
            const pessoaRelacao: IPessoaRelacao = { codigo: 0, codigoPessoa: state.pessoa.codigo, tipoRelacao };
            pessoaRelacoes.push(pessoaRelacao);
          }
        });
      }
      state.pessoa.relacoes = pessoaRelacoes;
    }

    function preparaPersistenciaMarcadores() {
      const pessoaMarcadores: IPessoaMarcador[] = [];
      if (state.marcadoresSelecionados.length > 0) {
        state.marcadoresSelecionados.forEach((marcador) => {
          const marcadorExistente = state.pessoa.marcadores.find((c) => c.marcador === marcador);
          if (marcadorExistente !== undefined) {
            pessoaMarcadores.push(marcadorExistente);
          } else {
            const pessoaMarcador: IPessoaMarcador = {
              codigo: 0, codigoPessoa: state.pessoa.codigo, tipoRelacao: props.relacaoPrincipalOperacao, marcador,
            };
            pessoaMarcadores.push(pessoaMarcador);
          }
        });
      }
      state.pessoa.marcadores = pessoaMarcadores;
    }

    function preparaPersistenciaFuncaoColaborador() {
      if (UtilitarioGeral.validaLista(state.departamentoFuncaoColaborador) && verificaApresentacaoColaborador()) {
        const departamentos: IColaboradorDepartamentoColaborador[] = [];
        const funcoes: IColaboradorFuncaoColaborador[] = [];

        state.departamentoFuncaoColaborador.forEach((departamentoFuncaoColaborador) => {
          let novoDepartamento = true;
          if (UtilitarioGeral.validaLista(state.pessoa.colaborador.departamentos)) {
            const departamentoColaborador = state.pessoa.colaborador.departamentos.find((c) => c.codigoEmpresa === departamentoFuncaoColaborador.codigoEmpresa);
            if (departamentoColaborador !== undefined) {
              departamentoColaborador.codigoDepartamentoColaborador = departamentoFuncaoColaborador.codigoDepartamento;
              departamentos.push(departamentoColaborador);
              novoDepartamento = false;
            }
          }

          if (novoDepartamento) {
            const departamentoColaborador: IColaboradorDepartamentoColaborador = {} as IColaboradorDepartamentoColaborador;
            departamentoColaborador.codigo = 0;
            departamentoColaborador.codigoColaborador = state.pessoa.colaborador.codigo;
            departamentoColaborador.codigoDepartamentoColaborador = departamentoFuncaoColaborador.codigoDepartamento;
            departamentoColaborador.codigoEmpresa = departamentoFuncaoColaborador.codigoEmpresa;
            departamentos.push(departamentoColaborador);
          }

          let novaFuncao = true;
          if (UtilitarioGeral.validaLista(state.pessoa.colaborador.funcoes)) {
            const funcaoColaborador = state.pessoa.colaborador.funcoes.find((c) => c.codigoEmpresa === departamentoFuncaoColaborador.codigoEmpresa);
            if (funcaoColaborador !== undefined) {
              funcaoColaborador.codigoFuncaoColaborador = departamentoFuncaoColaborador.codigoFuncao;
              funcoes.push(funcaoColaborador);
              novaFuncao = false;
            }
          }

          if (novaFuncao) {
            const funcaoColaborador: IColaboradorFuncaoColaborador = {} as IColaboradorFuncaoColaborador;
            funcaoColaborador.codigo = 0;
            funcaoColaborador.codigoColaborador = state.pessoa.colaborador.codigo;
            funcaoColaborador.codigoFuncaoColaborador = departamentoFuncaoColaborador.codigoFuncao;
            funcaoColaborador.codigoEmpresa = departamentoFuncaoColaborador.codigoEmpresa;
            funcoes.push(funcaoColaborador);
          }
        });

        state.pessoa.colaborador.departamentos = departamentos;
        state.pessoa.colaborador.funcoes = funcoes;
      }
    }

    function prepararPersistenciaTiposDocumento() {
      if (UtilitarioGeral.objetoValido(state.pessoa.cliente)) {
        state.pessoa.cliente.tiposDocumentos = [] as IClienteTipoDocumentoFinanceiro[];

        state.tiposDocumentos.forEach((t) => {
          const tipoDocumento = {} as IClienteTipoDocumentoFinanceiro;
          tipoDocumento.codigoCliente = state.pessoa.cliente.codigo;
          tipoDocumento.codigoTipoDocumentoFinanceiro = t;
          state.pessoa.cliente.tiposDocumentos.push(tipoDocumento);
        });
      }
    }

    function preparaPersistenciaCamposPersonalizados() {
      if (state.exibirCamposPersonalizados) {
        state.pessoa.camposPersonalizados = [] as ICampoPersonalizado[];
        state.personalizacaoTela.gruposCamposPersonalizados.forEach((g) => {
          g.campos.forEach((c) => {
            state.pessoa.camposPersonalizados.push(c);
          });
        });
      }
    }

    function preparaPersistenciaInscricoes() {
      if (state.pessoa.inscricoes.length === 0) {
        const inscricao = {} as IPessoaInscricoes;
        inscricao.tipoIe = ETipoInscricaoEstadual.NaoContribuinte;
        inscricao.inscricaoEstadual = '';
        inscricao.principal = true;
        state.pessoa.inscricoes.push(inscricao);
      }
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      if (state.pessoa.telefone === undefined || state.pessoa.telefone === null) {
        state.pessoa.telefone = '';
      } else {
        state.pessoa.telefone = UtilitarioMascara.removerMascara(state.pessoa.telefone);
      }
      if (state.pessoa.tipoPessoa === ETipoPessoa.Juridica && !UtilitarioGeral.valorValido(state.pessoa.fiscal.tipoAtividade)) {
        apresentarMensagemAlerta('O tipo de atividade é obrigatório!');
        return;
      }

      preparaPersistenciaEmpresas();
      preparaPersistenciaRelacoes();
      preparaPersistenciaMarcadores();
      preparaPersistenciaFuncaoColaborador();
      preparaPersistenciaCamposPersonalizados();
      preparaPersistenciaInscricoes();
      prepararPersistenciaTiposDocumento();

      apresentarBarraProgresso('Aguarde por favor, estamos salvando as suas informações...');

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await props.servicoAPI.incluir(state.pessoa);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await props.servicoAPI.alterar(state.pessoa);
      }
      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          emit('cadastroConcluido', retorno.codigoRegistro);
        } else if (state.reaproveitadoCadastro) {
          sincronizarRegistro(EPermissaoDados.Incluir, 0);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);

        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
          telaBase.empresasSelecionadas.push(props.operacao.empresaSelecionada);
          state.relacoesSelecionadas.push(props.relacaoPrincipalOperacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.objetoValido(props.pessoaPreCadastro)) {
          state.pessoa = UtilitarioGeral.instanciaObjetoLocal(props.pessoaPreCadastro);
          telaBase.empresasSelecionadas = [];
          if (state.pessoa.empresas.length > 0) {
            state.pessoa.empresas.forEach((pessoaEmpresa) => {
              telaBase.empresasSelecionadas.push(pessoaEmpresa.codigoEmpresa);
            });
          }
        } else {
          if (props.operacao.listaPermissoesDados.length > 0) {
            await preencherPermissoesDados(props.operacao.listaPermissoesDados);
          } else {
            await obterPermissoes(ETipoPermissao.Dados);
          }
          await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

          telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        }

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        await obterPersonalizacaoTela();

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.pessoa = await props.servicoAPI.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);

          telaBase.empresasSelecionadas = [];
          if (state.pessoa.empresas.length > 0) {
            state.pessoa.empresas.forEach((pessoaEmpresa) => {
              telaBase.empresasSelecionadas.push(pessoaEmpresa.codigoEmpresa);
            });
          }

          state.relacoesSelecionadas = [];
          const relacoesPessoa: number[] = [];
          if (state.pessoa.relacoes.length > 0) {
            state.pessoa.relacoes.forEach((pessoaRelacao) => {
              relacoesPessoa.push(pessoaRelacao.tipoRelacao);
            });
          }
          state.relacoesSelecionadas = relacoesPessoa;
          prepararCategoriasPlanoContas();
          preparaDefinicaoDepartamentoFuncao(true);
          if (UtilitarioGeral.objetoValido(state.pessoa.cliente)) {
            state.pessoa.cliente.tiposDocumentos.forEach((t) => {
              state.tiposDocumentos.push(t.codigoTipoDocumentoFinanceiro);
            });
          }
          state.marcadoresSelecionados = [];
          if (state.pessoa.marcadores.length > 0) {
            state.pessoa.marcadores.forEach((pessoaMarcador) => {
              state.marcadoresSelecionados.push(pessoaMarcador.marcador);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          state.relacoesSelecionadas.push(props.relacaoPrincipalOperacao);
          prepararCategoriasPlanoContas();
          preparaDefinicaoDepartamentoFuncao(true);
        }
        if (UtilitarioGeral.objetoValido(props.pessoaPreCadastro)) {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          modalBase.textoBotaoSalvarNovo = '';
          telaBase.permissaoDados.incluir = true;
          telaBase.permissaoDados.visualizar = true;
        } else {
          telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        }

        verificaConceitoParaApresentarEmpresas();
      }

      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      computedOperacao,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
      prepararCategoriasPlanoContas,
      verificaApresentacaoFiscalInscricoes,
      verificaApresentacaoColaborador,
      estrategiaAlteracaoEmpresa,
      estrategiaAlteracaoRelacao,
      atualizarDadosConsultaCnpj,
      verificarUsoCpf,
      verificarUsoNome,
      UtilitarioGeral,
    };
  },
});
