
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { useCrudBase } from '@/core/composables/CrudBase';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import ServicoRastreabilidade from '@/servicos/Estoques/ServicoRastreabilidade';
import DetalheRastreabilidadeModal from './DetalheRastreabilidadeModal.vue';
import { IDTORastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTORastreabilidade';

export default defineComponent({
  name: 'CentralRastreabilidades',
  components: {
    TituloPadrao,
    Icone,
    Card,
    MensagemSemDados,
    BuscaGeral,
    PreviewPdf,
    DetalheRastreabilidadeModal,
  },
  setup() {
    const {
      telaBase, preencherDadosRota, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, filtrarPermissaoDadosUsuario,
      defineEmpresasSelecionadasComPermissao, apresentarMensagemAlerta, apresentarMensagemErro, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const { crudBase } = useCrudBase();
    const { gradeBase, carregarPreferenciasGrade, preparaParametrosConsulta } = useGradeBase();

    const servicoRastreabilidade = new ServicoRastreabilidade();
    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasPadrao = [
      {
        title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
      },
      {
        title: 'Código Prod.', dataIndex: 'codigoProduto', key: 'CodigoProduto', position: 1, visible: true, ordering: true, align: 'left', width: 140,
      },
      {
        title: 'Produto', dataIndex: 'descricaoProduto', key: 'DescricaoProduto', position: 2, visible: true, ordering: true, align: 'left', width: 280,
      },
      {
        title: 'UN', dataIndex: 'unidade', key: 'Unidade', position: 3, visible: true, ordering: true, align: 'center', width: 60,
      },
      {
        title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 4, visible: true, ordering: true, align: 'center', width: 130, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 5, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Saldo', dataIndex: 'quantidade', key: 'Quantidade', position: 6, visible: true, ordering: true, align: 'right', customRenderRow: ECustomRenderRow.MascararFracionado, width: 100,
      },
      {
        title: 'Ações', key: 'acoes', position: 7, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];

    gradeBase.colunasMobile = [
      {
        title: 'Rastreabilidade', dataIndex: '', key: 'Rastreabilidade', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      dados: [] as IDTORastreabilidade[],
      arquivosPdf: [] as IArquivoPdf[],
      rastreabilidadeSelecionada: {} as IDTORastreabilidade,
      buscaRapida: '',
      exibirDetalhe: false,
      exibirLancamento: false,
      exibirSelecao: false,
    });

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.empresasSelecionadas);
      if (telaBase.preferencias !== null) {
        carregarPreferenciasGrade(telaBase.preferencias);
      }
    }

    async function buscarDados() {
      state.dados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.empresasSelecionadas, gradeBase.paginacao.current, gradeBase.paginacao.pageSize, gradeBase.paginacao.total);
      parametrosConsulta.qtdeRegistrosPagina = 10;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      telaBase.carregando = true;

      const listaPaginada = await servicoRastreabilidade.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      state.dados = listaPaginada.dados;
      gradeBase.paginacao.total = listaPaginada.metaData.totalRegistros || 0;
      gradeBase.paginacao.current = listaPaginada.metaData.paginaAtual || 1;
      gradeBase.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
    }

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      await buscarDados();
    }

    async function imprimir(tipoArquivoRelatorio: ETipoArquivo, codigoRegistro?: number) {
      console.log('chamou imprimir');
      gradeBase.filtrosAplicados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.empresasSelecionadas, 1, 0, 0);
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoRastreabilidade.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimirSelecionado(index: number) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoRastreabilidade.relatorioMovimentacoes(state.dados[index].codigo, telaBase.empresasSelecionadas[0], ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    function abrirLancamento() {
      state.exibirLancamento = true;
    }

    function abrirSelecao() {
      state.exibirSelecao = true;
    }

    function visualizarSelecionado(index: number) {
      state.rastreabilidadeSelecionada = state.dados[index];
      state.exibirDetalhe = true;
    }

    onBeforeMount(async () => {
      preencherDadosRota();
      verificaConceitoParaApresentarEmpresas();
      await obterPermissoes(ETipoPermissao.Autorizacoes);
      await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Visualizar, false);
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(telaBase.empresasSelecionadas[0]);
      telaBase.propriedadesConsulta = await servicoRastreabilidade.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        gradeBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        gradeBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      await carregarPreferencias();
      await buscarDados();
    });

    return {
      state,
      telaBase,
      gradeBase,
      crudBase,
      storeSistema,
      ECustomRenderRow,
      EVinculoEmpresa,
      ETipoArquivo,
      UtilitarioMascara,
      buscarDados,
      aplicarFiltros,
      imprimir,
      imprimirSelecionado,
      visualizarSelecionado,
      abrirLancamento,
      abrirSelecao,
    };
  },
});
