
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import {
  IRastreabilidade, IRastreabilidadeArmamento, IRastreabilidadeLote, IRastreabilidadeOrigem,
} from '@/models/Entidades/Estoques/IRastreabilidade';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoRastreabilidade';
import SelecionarTipoRastreabilidade from '@/components/Estoques/Rastreabilidade/SelecionarTipoRastreabilidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import SelecionarTipoUsoArma from '@/components/Estoques/Rastreabilidade/SelecionarTipoUsoArma.vue';
import ServicoRastreabilidade from '@/servicos/Estoques/ServicoRastreabilidade';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';

export default defineComponent({
  name: 'LancamentoRastreabilidadeModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    codigoDefinicaoProduto: {
      type: Number,
      required: true,
    },
    itemEstoque: {
      type: Number,
      required: true,
    },
    rastreabilidades: {
      type: Array as () => IRastreabilidade[],
      required: true,
    },
    recurso: {
      type: String,
      default: '',
    },
    quantidade: {
      type: Number,
      default: 0,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarTipoRastreabilidade,
    CampoNumerico,
    SelecionarTipoUsoArma,
    MensagemSemDados,
    SelecionarData,
  },
  emits: ['update:visivel', 'salvar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase, carregarPreferenciasGrade } = useGradeBase();
    const servicoRastreabilidade = new ServicoRastreabilidade();
    const servicoProduto = new ServicoProduto();
    servicoRastreabilidade.requisicaoSistema();
    servicoProduto.requisicaoSistema();

    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasMobile = [
      {
        title: 'Rastreabilidade', dataIndex: '', key: 'Rastreabilidade', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      definicaoProduto: {} as IDTOProdutoDefinicao,
      rastreabilidade: {} as IRastreabilidade,
      rastreabilidades: [] as IRastreabilidade[],
      rastreabilidadeSelecionada: -1,
    });

    function preencherColunas() {
      switch (state.definicaoProduto.tipoRastreabilidade) {
        case ETipoRastreabilidade.Outros:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 140,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Rastreabilidades', dataIndex: 'qtdRastreabilidade', key: 'qtdRastreabilidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
            },
          ];
          break;
        case ETipoRastreabilidade.Lote:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 140,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Rastreabilidades', dataIndex: 'qtdRastreabilidade', key: 'qtdRastreabilidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Nº do Lote', dataIndex: 'numeroLote', key: 'NumeroLote', position: 5, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Ações', key: 'acoes', position: 7, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
            },
          ];
          break;
        case ETipoRastreabilidade.Armamento:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 140,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Rastreabilidades', dataIndex: 'qtdRastreabilidade', key: 'qtdRastreabilidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Nº Arma', dataIndex: 'numeroSerieArma', key: 'NumeroSerieArma', position: 5, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Nº Cano', dataIndex: 'numeroSerieCano', key: 'NumeroSerieCano', position: 6, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Ações', key: 'acoes', position: 7, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
            },
          ];
          break;
        default:
          gradeBase.colunasPadrao = [];
          break;
      }
    }

    function limparRastreabilidade() {
      state.rastreabilidade = {} as IRastreabilidade;
      state.rastreabilidade.rastreabilidadeLote = {} as IRastreabilidadeLote;
      state.rastreabilidade.rastreabilidadeArmamento = {} as IRastreabilidadeArmamento;
      state.rastreabilidade.origem = {} as IRastreabilidadeOrigem;
    }

    async function limparTela() {
      state.definicaoProduto = {} as IDTOProdutoDefinicao;
      state.rastreabilidades = [] as IRastreabilidade[];
      state.rastreabilidadeSelecionada = -1;
      limparRastreabilidade();
    }

    function validarCampos() {
      if (state.rastreabilidade.qtdRastreabilidade === 0 || state.rastreabilidade.qtdRastreabilidade === undefined) {
        apresentarMensagemAlerta('A quantidade de rastreabilidades deve ser informada!');
        return false;
      }

      if (state.definicaoProduto.tipoRastreabilidade === ETipoRastreabilidade.Lote) {
        if (state.rastreabilidade.rastreabilidadeLote.numeroLote === '' || state.rastreabilidade.rastreabilidadeLote.numeroLote === undefined) {
          apresentarMensagemAlerta('O número do lote deve ser informado!');
          return false;
        }

        if (!UtilitarioData.validaDataPreenchida(state.rastreabilidade.rastreabilidadeLote.dataFabricacao)) {
          apresentarMensagemAlerta('A data de fabricação deve ser informada!');
          return false;
        }

        if (!UtilitarioData.validaDataPreenchida(state.rastreabilidade.rastreabilidadeLote.dataVencimento)) {
          apresentarMensagemAlerta('A data de vencimento deve ser informada!');
          return false;
        }

        if (state.rastreabilidade.rastreabilidadeLote.dataFabricacao > state.rastreabilidade.rastreabilidadeLote.dataVencimento) {
          apresentarMensagemAlerta('A data de vencimento não pode ser menor que a data de fabricação');
          return false;
        }
      }

      if (state.definicaoProduto.tipoRastreabilidade === ETipoRastreabilidade.Armamento) {
        if (state.rastreabilidade.rastreabilidadeArmamento.descricao === '' || state.rastreabilidade.rastreabilidadeArmamento.descricao === undefined) {
          apresentarMensagemAlerta('A descrição do armamento deve ser informada!');
          return false;
        }

        if (state.rastreabilidade.rastreabilidadeArmamento.numeroSerieArma === '' || state.rastreabilidade.rastreabilidadeArmamento.numeroSerieArma === undefined) {
          apresentarMensagemAlerta('O nº de série da arma deve ser informado!');
          return false;
        }

        if (state.rastreabilidade.rastreabilidadeArmamento.numeroSerieCano === '' || state.rastreabilidade.rastreabilidadeArmamento.numeroSerieCano === undefined) {
          apresentarMensagemAlerta('O nº de série do cano deve ser informado!');
          return false;
        }
      }

      return true;
    }

    function adicionar() {
      const validacao = validarCampos();
      if (!validacao) return;

      if (state.rastreabilidadeSelecionada === -1) {
        state.rastreabilidade.descricao = state.rastreabilidade.qtdRastreabilidade + state.definicaoProduto.unidade;
        state.rastreabilidade.codigoItemEstoque = props.itemEstoque;
        state.rastreabilidade.tipo = state.definicaoProduto.tipoRastreabilidade;
        state.rastreabilidade.origem.quantidade = state.rastreabilidade.qtdRastreabilidade;
        state.rastreabilidade.origem.recurso = props.recurso;
        state.rastreabilidades.push(state.rastreabilidade);
      } else {
        state.rastreabilidades[state.rastreabilidadeSelecionada] = state.rastreabilidade;
      }
      limparRastreabilidade();
      state.rastreabilidadeSelecionada = -1;
    }

    function editar(index: number) {
      state.rastreabilidade = state.rastreabilidades[index];
      state.rastreabilidadeSelecionada = index;
    }

    function excluir(index: number) {
      state.rastreabilidades.splice(index, 1);
      state.rastreabilidadeSelecionada = -1;
      limparRastreabilidade();
    }

    function validaQuantidadeRastreabilidade() {
      let qtdTotal = 0;
      state.rastreabilidades.forEach((r) => {
        qtdTotal += r.origem.quantidade;
      });

      if (qtdTotal < props.quantidade) {
        apresentarMensagemAlerta('O valor total da rastreabilidade está menor que a quantidade total do produto');
        return false;
      }
      if (qtdTotal > props.quantidade) {
        apresentarMensagemAlerta('O valor total da rastreabilidade está maior que a quantidade total do produto');
        return false;
      }
      return true;
    }

    function confirmar() {
      if (state.rastreabilidades.length === 0) {
        apresentarMensagemAlerta('Nenhuma rastreabilidade lançada!');
        return;
      }
      if (!validaQuantidadeRastreabilidade()) {
        return;
      }
      emit('salvar', state.rastreabilidades);
      modalBase.computedVisivel = false;
    }

    async function obterDadosProduto() {
      state.definicaoProduto = await servicoProduto.obterDadosDetalhesDefinicao(props.codigoDefinicaoProduto);
      state.definicaoProduto.tipoRastreabilidade = ETipoRastreabilidade.Lote;
      preencherColunas();
      carregarPreferenciasGrade(telaBase.preferencias);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        obterDadosProduto();
        state.rastreabilidades = UtilitarioGeral.clonarObjeto(props.rastreabilidades);
        telaBase.carregando = false;
      }
    });

    watch(async () => props.rastreabilidades, async () => {
      state.rastreabilidades = props.rastreabilidades;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      ETipoRastreabilidade,
      ECustomRenderRow,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      adicionar,
      editar,
      excluir,
      confirmar,
    };
  },
});
